@import url(https://fonts.googleapis.com/css?family=Barlow:300&display=swap);
@font-face{
    font-family: 'Barlow', sans-serif;
    src: url('https://fonts.googleapis.com/css?family=Barlow:300&display=swap');

}


body {
    margin: 0;
    background-color: #42C25E;
}

img {
    width: 35%;
    height: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

#notice {
    font-family:'Barlow', sans-serif;
    font-size: 40px;
    color: whitesmoke;
    text-align: center;
    margin-top: 40px;
   
    
}
